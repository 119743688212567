import decode from 'jwt-decode';

const domain = process.env.REACT_APP_API_URL // API server domain

export default class AuthService {

    // Initializing important variables
    constructor() {
        this.fetch = this.fetch.bind(this) // React binding stuff
        this.login = this.login.bind(this)
        this.getProfile = this.getProfile.bind(this)
    }

    login(username: string, password: string) {
        // Get a token from api server using the fetch api
        return this.fetch(`${domain}/login_check`, {
            method: 'POST',
            body: JSON.stringify({
                username,
                password
            })
        }).then(res => {
            this.setToken(res.id_token) // Setting the token in localStorage
            return Promise.resolve(res);
        })
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken() // GEtting token from localstorage
        return !!token && !this.isTokenExpired(token) // handwaiving here
    }

    isTokenExpired(token: string) {
        try {
            const decoded: any = decode(token);
            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return false;
        }
    }

    setToken(idToken: string) {
        // Saves user token to localStorage
        localStorage.setItem('id_token', idToken)
    }

    getToken(): string {
        // Retrieves the user token from localStorage
        const token = localStorage.getItem('id_token');
        if (token !== null) {
            return token;
        } else return '';
    }

    logout() {
        // Clear user token and profile data from localStorage
        localStorage.removeItem('id_token');
    }

    getProfile() {
        // Using jwt-decode npm package to decode the token
        return decode(this.getToken());
    }


    fetch(url: string, options: any) {
        // performs api calls sending the required authentication headers
        const headers: any = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',

        }

        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.getToken()
        }

        return fetch(url, {
            headers,
            ...options
        })
            .then(this._checkStatus)
            .then(this._parseJSON)
        // .then(response => response && response.json())
    }

    _checkStatus(response: any) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) {
            return response
        } else {
            var error: any = new Error(response.statusText)
            error.response = response
            // throw error
            return undefined;
        }
    }

    _parseJSON(response: any) {
        return response && response.text().then(function (text: string) {
            return text ? JSON.parse(text) : {}
        })
    }

    hasAnyAuthorities(hasAnyAuthorities: String[]) {
        if (this.loggedIn()) {
            const profile: any = this.getProfile();
            const authorities = profile.auth;
            if (authorities && authorities.length !== 0) {
                if (hasAnyAuthorities.length === 0) {
                    return true;
                }
                return hasAnyAuthorities.some(auth => authorities.includes(auth));
            }
            return false;
        }
        return false;
    }
}