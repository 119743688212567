import AuthService from "../services/AuthService";
import { INewUserDTO } from "../model/newUserDTO.model";
import { IPasswordChangeDTO } from "../model/passwordChangeDTO.model";
import { IUserDTO } from "../model/User.model";

const auth = new AuthService();
const apiUrl = process.env.REACT_APP_API_URL

export const createUser = (user: INewUserDTO) => {
    return auth.fetch(`${apiUrl}/users`,
        {
              method: "POST",
              body: JSON.stringify(user)
        })
}

export const modifyUser = (user: any) => {
    return auth.fetch(`${apiUrl}/lightUpdateUsers`,
        {
              method: "PUT",
              body: JSON.stringify(user)
        })
}

export const deleteUser = (id: number) => {
    return auth.fetch(`${apiUrl}/delUser?id=${id}`,
        {
              method: "DELETE",
              
        })
}

export const changePassword = (password: IPasswordChangeDTO) => {
    return auth.fetch(`${apiUrl}/account/change-password`,
        {
              method: "POST",
              body: JSON.stringify(password)
        })
}


export const getAllUsersWithAuthorities = () => {
    return auth.fetch(`${apiUrl}/usersWithAuthorities`, {})
}

export const setCoocToUser = (cooc: String, userId: number ) => {
    return auth.fetch(`${apiUrl}/setCoocToUser?cooc=${cooc}&userId=${userId}`,
        {
              method: "POST",
        })
}

export const removeCoocFromUser = (coocId: number, userId: number ) => {
    return auth.fetch(`${apiUrl}/removeCoocFromUser?coocId=${coocId}&userId=${userId}`,
        {
              method: "DELETE",
        })
}


export const findAllNumocl = () => {
    return auth.fetch(`${apiUrl}/numocls`,
        {
              method: "GET",
        })
}

export const findAllAuthorites = () => {
    return auth.fetch(`${apiUrl}/users/authorities`,
        {
              method: "GET",
        })
}

export const setAuthorityToUser = (authority: String, userId: number ) => {
    return auth.fetch(`${apiUrl}/setAuthorityToUser?auth=${authority}&userId=${userId}`,
        {
              method: "POST",
        })
}

export const removeAuthorityToUser = (authority: String, userId: number ) => {
    return auth.fetch(`${apiUrl}/removeAuthorityFromUser?auth=${authority}&userId=${userId}`,
        {
              method: "POST",
        })
}