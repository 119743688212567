import React from 'react';
import './modules.css';
import { Col, Card, Row, Container } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus, faUserShield, faUserTag } from "@fortawesome/free-solid-svg-icons";

interface props {
    history: any
}

export const Home = (props: props) => {

    const handleClickCreateUser = () => {
        props.history.push("/createUser");
    }
    const handleClickGestionUsers = () => {
        props.history.push("/gestionUsers");
    }

    return (
        <div style={{width: "100%", textAlign:"center"}}>
        <Row style={{display:"flex"}}>
            <Col md={2} />
            <Col md={3} className="marged">
                <Card className ="cursorPointer padded" onClick={handleClickCreateUser}>
                    <div className="alignCenter">
                    <h1>Création d'un utilisateur</h1>
                    <FontAwesomeIcon icon={faUserPlus} size="6x" />
                    </div>
                </Card>
            </Col>
            <Col md={3} className="marged ">
                <Card className ="cursorPointer padded" onClick={handleClickGestionUsers}>
                    <div className="alignCenter">
                    <h1>Gestion des utilisateurs</h1>
                    <FontAwesomeIcon icon={faUserTag} size="6x" />
                    </div>
                </Card>
            </Col>
            <Col md={2} />
        </Row>
        </div>
    )
}